import styled from "styled-components";
import React, {useEffect} from "react";

const StyledSection = styled.section`
    width: 100%;
    height: 100%;
`

const StyledImageSlider = styled.div`
    width: 100%;
    height: 100%;
    white-space: nowrap;
    transition: .3s ease-in-out;
`

const StyledImage = styled.img`
    display: inline-block;
    
    object-fit: cover;
    width: 100%;
    height: 100%;
`;

const StyledSlideshowDots = styled.div`
    position: absolute;
    bottom: 1rem;
    width: 100%;
    text-align: center;
`

const StyledSlideshowDot = styled.div`
    display: inline-block;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    cursor: pointer;
    margin: 1.5rem .7rem 0;
    background-color: white;
    border: solid var(--color-dark-gray) .15rem;

    &.active {
        background-color: var(--color-purple)
    }
`
let keyboardInterrupted = false;
export default function Slideshow({data}) {
    const [index, setIndex] = React.useState(0);
    const delay = (data.time/ data.images.length) * 1000;

    useEffect(() => {
        keyboardInterrupted = false;
        for (let x = 1; x < data.images.length; x++) {
           setTimeout(function() {
               if (keyboardInterrupted) {
                    return;
               }
               setIndex((prevIndex) =>
                   prevIndex === data.images.length - 1 ? 0 : prevIndex + 1
               )
            }, delay * x
           )}
    }, [data]);

    return (
        <StyledSection>
            <StyledImageSlider style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}>
                {data.images.map((image, index) =>
                    <StyledImage src={image} key={index}/>
                )}
            </StyledImageSlider>
            <StyledSlideshowDots>
                {data.images.length > 1 && data.images.map((_, idx) => (
                    <StyledSlideshowDot
                        key={idx}
                        className={`slideshowDot${index === idx ? " active" : ""}`}
                        onClick={() => {
                            setIndex(idx);
                            keyboardInterrupted = true
                        }}
                        ></StyledSlideshowDot>
                ))}
            </StyledSlideshowDots>
        </StyledSection>
    );
}
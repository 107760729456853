import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Home, {homeLoader} from "./routes/Home/Home";

function App() {

    const router = createBrowserRouter([
        {
            path: '/',
            loader: homeLoader,
            element: <Home />,
        },
    ]);



    return (
        <RouterProvider router={router} />
    )
}

export default App;
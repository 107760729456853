import styled from "styled-components";
import React from "react";

const StyledQrCode = styled.article`
    display: flex;
    flex-direction: column;
    
    align-items: center;
    
    width: 16rem;
    height: 16rem;
    
    padding: 0 1rem 0 1rem;
    
    background-color: white;
    overflow: hidden;
    border-radius: 1rem;
`;

const StyledQrCodeTitle = styled.p`
    margin: 1.3rem 0 1rem 0;
    
    font-family: var(--font-text);
    font-size: 2rem;
    font-weight: var(--fw-regular);
    color: var(--color-black);
`;

const StyledQrCodeSection = styled.section`
    width: 70%;
    height: 60%;
    background-color: white;
`;

const StyledQrCodeImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
function hasQRcode(qrCode){
    if(qrCode != null){
        return(
            <StyledQrCode>
                <StyledQrCodeTitle>
                    Lees verder
                </StyledQrCodeTitle>
                <StyledQrCodeSection>
                    <StyledQrCodeImage
                        src={qrCode}
                    ></StyledQrCodeImage>
                </StyledQrCodeSection>
            </StyledQrCode>
        )
    }
}

export default function QrCodeContent({qrCode}) {
    let qrCodeContent = hasQRcode(qrCode);

    return (
        <section>
            {qrCodeContent}
        </section>
    );
}
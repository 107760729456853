import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { ReactComponent as Logo} from '../../assets/icons/logo-dorus.svg';
import { ReactComponent as Dots} from '../../assets/icons/dotted-row.svg';
import { ReactComponent as Waves} from '../../assets/icons/waves.svg';
import SolarpanelsDayGraph from "../blocks/SolarpanelsDayGraph";

const StyledSolarpanels = styled.section`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
`;

const StyledHeading1 = styled.h1`
    padding-top: 3rem;
    height: 5%;
    font-family: var(--font-header);
    font-size: var(--fs-heading-1);
    font-weight: var(--fw-bold);
    order: 1;
`;

const StyledInformation = styled.section `
    display: flex;
    overflow: hidden;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 80%;
    height: 90%;

    gap: 5rem;

    order: 2;
`;

const StyledDots = styled(Dots)`
    position: fixed;

    width: 28rem;

    left: -15rem;
    bottom: 15%;

    @media screen and (min-width: 3840px) {
        transform: scale(2);
    }
`;

const StyledWaves = styled(Waves)`
    position: fixed;
    
    width: 48rem;
    
    right: -20rem;
    top: 15%;

    @media screen and (min-width: 3840px) {
        transform: scale(2);
    }
`;

const StyledLogo = styled(Logo)`
    width: 12rem;
    height: 3.6rem;
    order: 3;
    margin-bottom: 3.5rem;
`;
export default function Solarpanels({data}) {
    return (
        <StyledSolarpanels>
            <StyledInformation>
                <SolarpanelsDayGraph data={data.solarpanels.graphData}></SolarpanelsDayGraph>
            </StyledInformation>
            <StyledDots />
            <StyledWaves />
            <StyledLogo />
        </StyledSolarpanels>
    );
}
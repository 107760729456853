import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import WeatherDetail from "../blocks/WeatherDetail";
import WeatherRain from "../blocks/WeatherRain";
import { ReactComponent as Logo} from '../../assets/icons/logo-dorus.svg';
import { ReactComponent as Dots} from '../../assets/icons/dotted-row.svg';
import { ReactComponent as Waves} from '../../assets/icons/waves.svg';

const StyledWeather = styled.section`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    width: 100%;
    height: 100%;
`;

const StyledInformation = styled.section `
    display: flex;
    overflow: hidden;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    width: 80%;
    height: 90%;
    
    gap: 5rem;

    order: 1;
`;

const StyledDots = styled(Dots)`
    position: fixed;

    width: 28rem;

    left: -15rem;
    bottom: 15%;
    
`;

const StyledWaves = styled(Waves)`
    position: fixed;

    width: 48rem;

    right: -20rem;
    top: 15%;
`;

const StyledLogo = styled(Logo)`
    width: 12rem;
    height: 3.6rem;
    order: 2;
    
    @media screen and (min-width: 3840px) {
        width: 30rem;
        bottom: 3rem;
    }
`;
export default function Weather({data}) {

    return (
        <StyledWeather>
            <StyledInformation>
                <WeatherRain data={data.weather.rainPrediction}></WeatherRain>
            </StyledInformation>
            <StyledLogo />
            <StyledDots />
            <StyledWaves />
        </StyledWeather>
    );
}
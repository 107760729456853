import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Wind} from '../icons/weather/wind.svg';

const StyledWeatherDetail = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33%;
    height: 90%;
    
    background-color: var(--color-blue);
    border-radius: 1.25rem;
`;

const StyledHeading1 = styled.h1`
    font-family: var(--font-header);
    font-size: var(--fs-heading-1);
    font-weight: var(--fw-bold);
    
    
    @media screen and (min-width: 3840px) {
        font-size: 7.5rem;
    }
`;

const StyledHeading2 = styled.h2`
    font-size: var(--fs-heading-2);
    font-weight: var(--fw-bold);

    @media screen and (max-width: 1920px) {
        margin-top: -2rem;
    }

    @media screen and (min-width: 3840px) {
        font-size: 7rem;
    }
`;

const StyledFigure = styled.figure`
    width: 25rem;
    height: 25rem;

    @media screen and (max-width: 1920px) {
        margin-top: -3rem;
    }

    @media screen and (min-width: 3840px) {
        width: 50rem;
        height: 50rem;
        margin: -5rem 0 -5rem 0;
    }
`;

const StyledImage = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
`;

const StyledDescription = styled.p`
    font-size: var(--fs-heading-3);
    font-weight: var(--fw-regular);

    @media screen and (min-width: 3840px) {
        font-size: 6rem;
    }
`;

const StyledWind = styled.section`
    display: flex;
    flex-direction: row;
`;

const StyledIcon = styled(Wind)`
    width: 10rem;
    height: 10rem;

    @media screen and (min-width: 3840px) {
        width: 20rem;
        height: 20rem;
    }
`;

const StyledHeading3 = styled.h3`
    font-size: var(--fs-heading-2);
    font-weight: var(--fw-bold);

    @media screen and (min-width: 3840px) {
        font-size: 7rem;
    }
`;
export default function WeatherDetail({heading, icon, temp, desc, wind, direction}) {


    return (
            <StyledWeatherDetail>
                <StyledHeading1>{heading}</StyledHeading1>
                <StyledFigure>
                    <StyledImage src={require('../icons/weather/' + icon + '.png')} />
                </StyledFigure>
                <StyledHeading2>{temp}&#8451;</StyledHeading2>
                <StyledDescription>{desc}</StyledDescription>
                <StyledWind>
                    <StyledIcon />
                    <StyledHeading3>{wind}</StyledHeading3>
                </StyledWind>
                <StyledDescription>{direction}</StyledDescription>
            </StyledWeatherDetail>
    );
}
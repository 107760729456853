import {createGlobalStyle} from 'styled-components';
import normalize from 'styled-normalize';

const GlobalStyle = createGlobalStyle`
  ${normalize}
  
  * {
      box-sizing: border-box;
  }
  
  :root {
      /* Colour properties */
      --color-white: #ffffff;
      --color-black: #000000;
      --color-dark-gray: #212121;
      
      --color-green: #C3D8B6;
      --color-green-bright: #C4EABE;
      --color-green-light: #E4ECDF;
      
      --color-orange: #F29E4D;
      --color-yellow: #FFEC4A;
      
      --color-blue: #CBDFEF;
      --color-purple: #7E6992;
      --color-purple-dark: #211845;
      
      /* Font properties */
      --font-header: 'Poppins', sans-serif;
      --font-text: 'Lora', sans-serif;
      
      --fw-regular: 400;
      --fw-semi-bold: 600;
      --fw-bold: 700;
      --fw-extra-bold: 800;
      
      --fs-heading-1: 5rem;
      --fs-heading-2: 4rem;
      --fs-heading-3: 3.5rem;
      --fs-body: 3rem;
      --fs-date: 2.5rem;
  }
  
  html, body {
      margin: 0;
      padding: 0;
      font-size: 62.5%;
      font-family: var(--font-text);
      background-color: var(--color-white);
  }
`

export default GlobalStyle;


import React from 'react';
import styled from 'styled-components';
import GlobalStyle from '../../globalStyles';
import {Await, useLoaderData} from 'react-router-dom';
import Narrowcasting from '../../assets/components/Narrowcasting';
import DorusBackground from '../../assets/images/Dorus.png';

const StyledContainer = styled.div`
    width: 100vw;
    height: 100vh;
    
    overflow: hidden;
`;

//loader api
export async function homeLoader() {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/narrowcasting`)
        return await response.json()
    } catch (error) {
        return defaultErrorPage()
    }
}

function defaultErrorPage() {
    return [
        {
            "id": 0,
            "timestamp": Date.now(),
            "title": "Failed to Fetch",
            "time": 120,
            "youtubeUrl": "",
            "type": "filler",
            "images": [DorusBackground]
        }
    ]
}

export default function Home() {
    const data  = useLoaderData();
    return (
        <StyledContainer>
            <React.Suspense>
                <Await resolve={data}>
                    {(content) => (
                        <Narrowcasting data = {content}/>
                    )}
                </Await>
            </React.Suspense>
            <GlobalStyle />

        </StyledContainer>
    );
}
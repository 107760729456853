import React from 'react';
import styled from 'styled-components';

const StyledSolarpanelsDetail = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33%;
    height: 90%;
    
    background-color: var(--color-blue);
    border-radius: 1.25rem;
    
    gap: 1rem;
`;

const StyledHeading1 = styled.h1`
    font-family: var(--font-header);
    font-size: var(--fs-heading-1);
    font-weight: var(--fw-bold);
  
    @media screen and (min-width: 3840px) {
        font-size: 7.5rem;
    }
`;

const StyledHeading2 = styled.h2`
    font-size: var(--fs-heading-2);
    font-weight: var(--fw-bold);
  
    @media screen and (min-width: 3840px) {
        font-size: 7rem;
    }
`;

const StyledFigure = styled.figure`
    width: 16rem;
    height: 16rem;

    @media screen and (min-width: 3840px) {
        width: 40rem;
        height: 40rem;
    }
`;

const StyledImage = styled.img`
    object-fit: cover;
    width: 100%;
    min-height: 100%;
`;

const StyledDescription = styled.p`
    font-size: var(--fs-heading-3);
    font-weight: var(--fw-regular);

    @media screen and (min-width: 3840px) {
        font-size: 6rem;
    }
`;

export default function SolarpanelsDetail({heading, icon, watt}) {
    let comparison
    let power

    switch (icon) {
        case 'coffee':
            comparison = parseInt(watt / 67) + ' koppen koffie'
            power = watt + ' Watt'
            break;

        case 'tea':
            comparison = parseInt(watt / 37) + ' koppen thee'
            power = watt + ' Watt'
            break;

        case 'music':
            comparison = parseInt(watt / 13) + ' uur muziek'
            power = watt + ' Watt'
            break;

        case 'tosti':
            comparison = parseInt(watt / 37.5) + " hete tosti's"
            power = +(watt / 1000).toFixed(1) + ' kWh'
            break;

        case 'toast':
            comparison = parseInt(watt / 25) + " geroosterd brood"
            power = +(watt / 1000).toFixed(1) + ' kWh'
            break;

        case 'airfryer':
            comparison = parseInt(watt / 74) + " geairfryde kroketten"
            power = +(watt / 1000).toFixed(1) + ' kWh'
            break;

        case 'smartphone':
            comparison = parseInt(watt / 1200) + " opgeladen telefoons"
            power = +(watt / 1000).toFixed(1) + ' kWh'
            break;

        case 'tree':
            comparison = parseInt(watt / 50000) + ' bomen geplant'
            power = +(watt / 1000000).toFixed(1) + ' mWh'
            break;

        case 'dishwasher':
            comparison = parseInt(watt / 950) + ' wasbeurten'
            power = +(watt / 1000000).toFixed(1) + ' mWh'
            break;

    }

    comparison = comparison.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    power = power.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    console.log(power)

    return (
            <StyledSolarpanelsDetail>
                <StyledHeading1 style={{marginBottom: '9rem'}}>{heading}</StyledHeading1>
                <StyledHeading2 style={{marginBottom: '-2rem'}}>{power}</StyledHeading2>
                <StyledHeading2 style={{marginBottom: '-2rem'}}>=</StyledHeading2>
                <StyledDescription style={{marginBottom: '9rem'}}>{comparison}</StyledDescription>
                <StyledFigure>
                    <StyledImage src={require('../icons/solarpanels/' + icon + '.png')} />
                </StyledFigure>

            </StyledSolarpanelsDetail>
    );
}
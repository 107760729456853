import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Filler from './Filler';
import FullscreenImage from './FullscreenImage';
import SquareImage from './SquareImage';
import LandscapeImage from './LandscapeImage';
import PortraitImage from './PortraitImage';
import Event from './Event';
import Weather from './Weather';
import WeatherGraph from './WeatherGraph';
import Solarpanels from "./Solarpanels";
import SolarpanelsGraph from "./SolarpanelsGraph";
import { ReactComponent as Pause} from '../icons/pause.svg';
import {homeLoader} from "../../routes/Home/Home";

const StyledNarrowcasting = styled.section`
    height: inherit;
`;

const StyledAnimation = styled.div`
    position: absolute;
    
    width: 100vw;
    height: 100vh;
    
    background-color: black;
    transition: .5s ease-in-out;
    z-index: 100;
`;

const StyledPause = styled(Pause)`
    position: absolute;
    left: 2rem;
    bottom: 2rem;
    
    width: 10rem;
    height: 10rem;
    
    z-index: 100;
    opacity: 0;

`;

var currentSlide = 0;

var dataList = [];
var currentScreen = 0;
var screenTimer = 0;
var animationTimer = 0;
var timerPaused = false;
export default function Narrowcasting({data}) {
    let fetchedData = data
    const [component, setComponent] = useState(null);

    useEffect(() => {
        setInputs();
        startLoop();
    }, []);

    function startLoop() {
        currentScreen = 0;
        dataList = []
        makeList();
        activateScreen(currentScreen)
    }

    function makeList() {
        for(let x = 0; x < fetchedData.length; x++) {
            let data = fetchedData[x]
            dataList.push(data);
            switch(data.type) {
                case "solarpanels":
                    if (data.solarpanels.graphData[0]) {
                        let solarGraph = { ...data, type: "solarpanelsgraph" };
                        dataList.push(solarGraph);
                    }
                    break;
                case "weather":
                    if (data.weather.rainPrediction[0]) {
                        let weatherGraph = {...data, type: "weathergraph"};
                        dataList.push(weatherGraph);
                    }
                    break;
            }
        }
    }

    function activateScreen(screenNumber) {
        setComponent(getComponent(dataList[screenNumber]));
        setTimeout(function() {
            document.getElementById('animation').style.opacity = '0';
            document.getElementById('animation').style.zIndex = 0;
        }, 350)


        startTimer(screenNumber)
    }

    function startTimer(screenNumber) {
        let delay = dataList[screenNumber].time;

        // Next screen timeout
        if(!timerPaused) {
            screenTimer = setTimeout(function(){
                currentScreen ++
                if(currentScreen >= dataList.length) {
                    const data = Promise.resolve(homeLoader());
                    data.then((data) => {
                        fetchedData = data
                        startLoop()
                    })
                } else {
                    activateScreen(currentScreen)
                }

            }, delay * 1000);

            // Animation timeout
            animationTimer = setTimeout(function() {
                document.getElementById('animation').style.opacity = '1';
                document.getElementById('animation').style.zIndex = 100;
            }, (delay - .5) * 1000)
        }
    }

    function getImageType(data, color, textColor) {
        switch(data.imageType) {
            case 'square':
                return <SquareImage data = {data} color = {color} textColor = {textColor}/>

            case 'landscape':
                return <LandscapeImage data = {data} color = {color} textColor = {textColor}/>

            case 'portrait':
                return <PortraitImage data = {data} color = {color} textColor = {textColor}/>

            case 'fullscreen':
                return <FullscreenImage data = {data} color = {color} textColor = {textColor}/>

            default:
                return <FullscreenImage data = {data} color = {color} textColor = {textColor}/>;
        }
    }
    function getComponent(entry) {
        if(!entry){
            return ;
        }
        switch (entry.type) {
            case 'filler':
                return <Filler data = {entry}/>

            case 'news':
                return getImageType(entry, 'var(--color-green)');

            case 'event':
                return <Event data = {entry}/>

            case 'meetthecoworkers':
                return getImageType(entry, 'var(--color-yellow');

            case 'ad':
                return getImageType(entry, 'var(--color-purple-dark)',  'var(--color-white)');
                
            case 'weather':
                return <Weather data={entry}/>
            case 'weathergraph':
                return <WeatherGraph data={entry}/>

            case 'solarpanels':
                return <Solarpanels data={entry}/>
            case 'solarpanelsgraph':
                return <SolarpanelsGraph data={entry}/>
        }
    }

    function setInputs() {
        document.addEventListener('keydown', function(event) {
            switch(event.key) {
                case ' ':
                    handlePause();
                    break;
                case 'ArrowLeft':
                    currentScreen --
                    setInputScreen();
                    break;
                case 'ArrowRight':
                    currentScreen ++
                    setInputScreen();
                    break;
            }
        });
    }

    function handlePause() {
        if(!timerPaused){
            timerPaused = true;
            clearTimeout(screenTimer);
            clearTimeout(animationTimer);
            document.getElementById('paused').style.opacity = '1';
        } else {
            timerPaused = false;
            activateScreen(currentScreen)
            document.getElementById('paused').style.opacity = '0';
        }
    }

    function setInputScreen() {
        // stop the timers
        clearTimeout(screenTimer);
        clearTimeout(animationTimer);
        document.getElementById('paused').style.opacity = '1';
        timerPaused = true;

        if(currentScreen >= dataList.length) {
            currentScreen = 0
        } else if (currentScreen < 0) {
            currentScreen = dataList.length - 1
        }

        activateScreen(currentScreen)

    }

    return (
        <StyledNarrowcasting>
            <StyledAnimation id={'animation'} />
            {component}
            <StyledPause id={'paused'}/>
        </StyledNarrowcasting>
    );
}
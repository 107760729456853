import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Logo} from '../../assets/icons/logo-dorus.svg';
import { ReactComponent as Circle} from '../../assets/icons/striped-circle.svg';
import { ReactComponent as Row} from '../../assets/icons/dotted-row.svg';
import QrCodeContent from "../blocks/QrCodeContent";
import Slideshow from "../blocks/Slideshow";


const StyledNews = styled.section`
    background-color: var(--color-white);
`;

const StyledSection = styled.section`
    position: absolute;
    display: flex;
    flex-direction: row;

    width: 85%;
    height: 80%;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    background-color: var(--color-green);
    border-radius: 1.25rem;
`;

const StyledImageSection = styled.section`
    display: flex;
    justify-content: right;
    width: 50%;
    order: 1;

`

const StyledImageContainer = styled.figure`
    position: relative;

    width: 100%;
    max-height: 85%;
    
    top: -5%;
    right: -3%;

    border-radius: 1.25rem;
    overflow: hidden;
`;

const StyledTextArea = styled.article`
    display: flex;
    flex-direction: column;
    
    width: 50%;

    padding-top: 3%;
    padding-left: 3%;

    order: 2;
`;

const StyledHeading = styled.h1`
    width: 90%;
    
    font-family: var(--font-header);
    font-size: var(--fs-heading-1);
    font-weight: var(--fw-bold);
    
    order: 1;

    @media screen and (max-width: 1920px) {
        font-size: 7rem;
    }

    @media screen and (min-width: 3840px) {
        font-size: 9rem;
    }
`;

const StyledText = styled.p`
    width: 90%;

    font-size: var(--fs-body);
    font-weight: var(--fw-regular);
    word-wrap: break-word;
    
    order: 2;

    @media screen and (max-width: 1920px) {
        font-size: 4rem;
        margin-top: -1rem;
    }

    @media screen and (min-width: 3840px) {
        margin-top: -1rem;
        font-size: 7rem;
    }
`;

const StyledCircle = styled(Circle)`
    position: fixed;

    width: 16rem;

    right: 4rem;
    top: 20rem;

    @media screen and (min-width: 3840px) {
        right: 20rem;
        top: 30rem;
    }
`;

const StyledRow = styled(Row)`
    position: fixed;

    width: 24rem;

    left: -14rem;
    bottom: 35%;

    @media screen and (min-width: 3840px) {
        left: -1rem;
        bottom: 30rem;
    }
`;

const StyledLogo = styled(Logo)`
  position: fixed;
  width: 12rem;
  height: 3.6rem;

  left: 50%;
  bottom: 3.5rem;
  transform: translate(-50%);
`;

const StyledQrCodeContainer = styled.section`
    position: absolute;
    bottom: 4rem;
    right: 4rem;
`;

export default function SquareImage({data, color, textColor}) {
    return (
        <StyledNews >
            <StyledSection style={{'backgroundColor': color, 'color': textColor}}>
                <StyledTextArea>
                    <StyledHeading>{data.title}</StyledHeading>
                    <StyledText>{data.text}</StyledText>
                </StyledTextArea>
                <StyledImageSection>
                    <StyledImageContainer>
                        <Slideshow data={data}></Slideshow>
                    </StyledImageContainer>
                    <StyledQrCodeContainer>
                        <QrCodeContent qrCode ={data.qrCodeUrl} ></QrCodeContent>
                    </StyledQrCodeContainer>
                </StyledImageSection>
            </StyledSection>
            <StyledCircle />
            <StyledRow />
            <StyledLogo />
        </StyledNews>
    );
}
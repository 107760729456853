import React from 'react';
import styled from 'styled-components';
import {CategoryScale, Chart as ChartJS} from 'chart.js/auto';
import {Line} from "react-chartjs-2";

const StyledSolarpanels = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;

    background-color: var(--color-blue);
    border-radius: 1.25rem;
    padding: 4rem;
`;

const StyledHeading1 = styled.h1`
    position: absolute;
    top: 7.5rem;
    font-family: var(--font-header);
    font-size: var(--fs-heading-1);
    font-weight: var(--fw-bold);


    @media screen and (min-width: 3840px) {
        font-size: 7.5rem;
    }
`;

const StyledLine = styled(Line)`
    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
`;

export default function SolarpanelsDayGraph({data}) {

    ChartJS.register(CategoryScale);

    let labels = [];
    let values = [];

    data.forEach(mapSolarpanels)
    let highestValue = Math.round(Math.max(...values));
    // minimum van 1 nodig om de y-as weer te geven
    if (highestValue > 10){
        highestValue = parseInt(highestValue) + 5;
    } else {
        highestValue = 10;
    }

    function mapSolarpanels(item, index){
        labels.push(item['date'].split(' ').slice(1).join(':').split(':').slice(0, 2).join(':'));

        if (item['value'] === null) {
            values.push(0);
        } else {
            values.push(item['value'] / 1000);
        }
    }



    const chart = {
        labels: labels,
        datasets: [
            {
                data: values,
                fill: true,
                backgroundColor: 'rgba(126, 105, 146, 0.5)',
                borderColor: '#000000',
                borderWidth: 5,
                radius: 0,
            }
        ]
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                min: 0,
                max: highestValue,
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        size: 20,
                        family: 'Poppins'
                    },
                    callback: function(value, index, ticks){
                        return value + ' kWh';
                    }
                },
            },
            x: {
                grid : {
                  display: true
                },
                ticks: {
                    font: {
                        size: 20,
                        family: 'Poppins'
                    },
                    callback: function(val, index){
                        return index % 4 === 0 ? this.getLabelForValue(val) : '';
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            // title: {
            //     display: true,
            //     text: 'Vandaag opgewekt',
            //     color: '#000000',
            //     fullSize: true,
            //     font: {
            //         size: 35,
            //         family: "Poppins"
            //     }
            // },

        },
        animation: false,
    }

    return (
        <StyledSolarpanels>
            <StyledHeading1>Vandaag opgewekt</StyledHeading1>
            <StyledLine data={chart} options={options} />
        </StyledSolarpanels>
    );
}
import React from 'react';
import styled from 'styled-components';
import BackgroundImage from '../../assets/images/background-lunchtables.jpeg';
import { ReactComponent as Logo} from '../../assets/icons/logo-dorus.svg';
import { ReactComponent as Diamond} from '../../assets/icons/dotted-diamond.svg';
import QrCodeContent from "../blocks/QrCodeContent";


const StyledNews = styled.section`
    height: 100%;
    
    background-image: url(${BackgroundImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const StyledSection = styled.section`
    position: relative;
    overflow: hidden;
    
    width: 47%;
    height: 64%;
    
    top: 10%;
    
    background-color: var(--color-green);
    border-radius: 0 80rem 80rem 0;
    
    @media screen and (max-width: 1920px) {
        width: 60%;
        height: 70%;
    }
`;

const StyledTextArea = styled.article`
    display: flex;
    flex-direction: column;
    padding-left: 10%;
    
    @media screen and (max-width: 1920px) {
        padding: 5% 0 0 10%;
    }
`;

const StyledHeading = styled.h1`
    max-width: 75%;
    
    font-family: var(--font-header);
    font-size: var(--fs-heading-1);
    font-weight: var(--fw-bold);

    @media screen and (max-width: 1920px) {
        font-size: 7rem;
    }

    @media screen and (min-width: 3840px) {
        padding-top: 5rem;
        font-size: 9rem;
    }
`;

const StyledText = styled.p`
    width: 80%;
    
    font-size: var(--fs-body);
    font-weight: var(--fw-regular);
    word-wrap: break-word;

    @media screen and (max-width: 1920px) {
        font-size: 4rem;
        margin-top: -1rem;
    }

    @media screen and (min-width: 3840px) {
        margin-top: -1rem;
        font-size: 7rem;
    }
`;

const StyledDiamond = styled(Diamond)`
    display: flex;
    position: absolute;
    
    width: 15rem;

    right: 30%;
    bottom: -17%;
    
    fill: var(--color-white);

    @media screen and (max-width: 1920px) {
        width: 20rem;
        
        right: 30%;
        bottom: -15%;
    }

    @media screen and (min-width: 3840px) {
        transform: scale(2);
        right: 40%;
        bottom: -5%;
    }
`;

const StyledLogo = styled(Logo)`
    position: fixed;

    width: 17rem;

    right: 4rem;
    top: 1rem;

    @media screen and (min-width: 3840px) {
        width: 30rem;
        right: 7rem;
        top: 6rem;
    }
`;

const StyledQrCodeContainer = styled.section`
    position: absolute;
    bottom: 4rem;
    right: 4rem;
`;

export default function FullscreenImage({data, color, textColor}) {
    return (
        <StyledNews style={{backgroundImage: `url('${data.images[0]}')`}}>
            <StyledSection style={{'backgroundColor': color, 'color': textColor}}>
                <StyledTextArea>
                    <StyledHeading>{data.title}</StyledHeading>
                    <StyledText>{data.text}</StyledText>
                    <StyledDiamond />
                </StyledTextArea>
            </StyledSection>
            <StyledQrCodeContainer>
                <QrCodeContent qrCode ={data.qrCodeUrl} ></QrCodeContent>
            </StyledQrCodeContainer>
            <StyledLogo />
        </StyledNews>
    );
}
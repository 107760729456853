import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Logo} from '../../assets/icons/logo-dorus.svg';
import { ReactComponent as Wave} from '../../assets/icons/wave-pair.svg';
import { ReactComponent as Waves} from '../../assets/icons/waves.svg';
import QrCodeContent from "../blocks/QrCodeContent";
import Slideshow from "../blocks/Slideshow";


const StyledNews = styled.section`
    background-color: var(--color-white);
`;

const StyledSection = styled.section`
    position: absolute;
    display: flex;
    flex-direction: row;

    width: 85%;
    height: 80%;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    background-color: var(--color-yellow);
    border-radius: 1.25rem;
`;

const StyledTextArea = styled.article`
    display: flex;
    flex-direction: column;

    width: 40%;

    padding-top: 3%;
    padding-left: 3%;

    order: 1;
`;

const StyledHeading = styled.h1`
    width: 120%;
    
    font-family: var(--font-header);
    font-size: var(--fs-heading-1);
    font-weight: var(--fw-bold);

    order: 1;

    @media screen and (max-width: 1920px) {
        padding-left: 5%;
        font-size: 7rem;
    }

    @media screen and (min-width: 3840px) {
        font-size: 9rem;
    }
`;

const StyledText = styled.p`
    width: 100%;
    
    font-size: var(--fs-body);
    font-weight: var(--fw-regular);
    word-wrap: break-word;

    order: 2;

    @media screen and (max-width: 1920px) {
        padding-left: 5%;
        margin-top: -1rem;
        font-size: 4rem;
    }

    @media screen and (min-width: 3840px) {
        margin-top: -1rem;
        font-size: 7rem;
    }
`;

const StyledImageSection = styled.section`
    display: flex;
    justify-content: right;
    width: 60%;
    order: 2;

`

const StyledImageContainer = styled.figure`
    position: relative;

    width: 100%;
    height: 56.25%;

    top: 35%;
    right: -10%;

    border-radius: 1.25rem;
    overflow: hidden;
`;

const StyledWave = styled(Wave)`
    position: fixed;

    width: 40rem;

    right: -5rem;
    top: 10rem;
`;

const StyledWaves = styled(Waves)`
    position: fixed;

    width: 48rem;

    left: -15rem;
    bottom: 15%;

    @media screen and (min-width: 3840px) {
        left: -10rem;
    }
`;

const StyledLogo = styled(Logo)`
  position: fixed;
  width: 12rem;
  height: 3.6rem;

  left: 50%;
  bottom: 3.5rem;
  transform: translate(-50%);
`;

const StyledQrCodeContainer = styled.section`
    position: absolute;
    top: 11rem;
    right: 4rem;
`;

export default function LandscapeImage({data, color, textColor}) {
    return (
        <StyledNews >
            <StyledSection style={{'backgroundColor': color, 'color': textColor}}>
                <StyledTextArea>
                    <StyledHeading>{data.title}</StyledHeading>
                    <StyledText>{data.text}</StyledText>
                </StyledTextArea>
                <StyledImageSection>
                    <StyledImageContainer>
                        <Slideshow data={data}></Slideshow>
                    </StyledImageContainer>
                    <StyledQrCodeContainer>
                        <QrCodeContent qrCode ={data.qrCodeUrl} ></QrCodeContent>
                    </StyledQrCodeContainer>
                </StyledImageSection>
            </StyledSection>
            <StyledWave />
            <StyledWaves />
            <StyledLogo />
        </StyledNews>
    );
}
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Logo} from '../../assets/icons/logo-dorus.svg';
import BackgroundImage from '../../assets/images/background-lunchtables.jpeg';

const StyledFiller = styled.section`
    height: 100%;
    
    background-image: url(${BackgroundImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const StyledLogo = styled(Logo)`
    position: fixed;

    width: 17rem;

    right: 4rem;
    bottom: 0;

    @media screen and (min-width: 3840px) {
        width: 30rem;
        right: 7rem;
        bottom: 6rem;
    }
`;
    function hasVideo(data){
        if(data.youtubeUrl != null){
            let youtubeUrl = data.youtubeUrl + "?autoplay=1&mute=1&controls=0";
            return(
                <iframe
                    width="100%"
                    height="100%"
                    src={youtubeUrl}
                    frameBorder="0"
                ></iframe>
            )
        }
    }
export default function Filler({data}) {
    let video = hasVideo(data);
    return (
        <StyledFiller style={{backgroundImage: `url('${data.images[0]}')`}}>
            {video}
            <StyledLogo />
        </StyledFiller>
    );
}
import React from 'react';
import styled from 'styled-components';
import BackgroundImage from '../../assets/images/background-lunchtables.jpeg';
import { ReactComponent as Logo} from '../../assets/icons/logo-dorus.svg';
import { ReactComponent as Triangles} from '../icons/playful-triangles.svg';
import QrCodeContent from "../blocks/QrCodeContent";

const StyledEvent = styled.section`
    height: 100%;
    
    background-image: url(${BackgroundImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
`;

const StyledSection = styled.section`
    position: relative;
    overflow: hidden;
    
    width: 30%;
    height: 75%;
    
    margin-left: 5%;
    
    top: 12.5%;
    
    background-color: var(--color-orange);
    border-radius: 2rem;
`;

const StyledTextArea = styled.article`
    display: flex;
    flex-direction: column;
    
    padding: 7%;
`;

const StyledHeading = styled.h1`
    font-family: var(--font-header);
    font-size: var(--fs-heading-1);
    font-weight: var(--fw-bold);

    @media screen and (max-width: 1920px) {
        margin: 2rem 3rem 0 3rem;
    }
    
    @media screen and (min-width: 3840px) {
        font-size: 9rem;
        margin: 2rem 3rem 0 3rem;
    }
`;

const StyledText = styled.p`
    padding-top: 5%;    
    
    font-family: var(--font-text);
    font-size: var(--fs-body);
    font-weight: var(--fw-regular);

    @media screen and (max-width: 1920px) {
        font-size: var(--fs-heading-3);
        margin: -0.5rem 3rem 0 3rem;
    }
    
    @media screen and (min-width: 3840px) {
        font-size: 7rem;
        margin: -0.5rem 3rem 0 3rem;
    }
`;

const StyledDate = styled.p`
    position: absolute;
 
    padding: 1rem 3rem;

    right: 3rem;
    bottom: 1rem;
    
    background-color: var(--color-white);
    border-radius: 10rem;
    
    font-family: var(--font-text);
    font-size: var(--fs-date);
    font-weight: var(--fw-regular);

    @media screen and (min-width: 3840px) {
        font-size: 5rem;
        margin-right: 3rem;
        padding: 2rem 4rem;
    }
`;

const StyledTriangles = styled(Triangles)`
    position: absolute;
    
    width: 50rem;
    height: 45rem;

    left: -20rem;
    bottom: -20rem;
    
    opacity: 50%;
    
    @media screen and (min-width: 3840px) {
        transform: scale(2);
    }
`;

const StyledLogo = styled(Logo)`
    position: fixed;

    width: 17rem;

    right: 4rem;
    top: 1rem;

    @media screen and (min-width: 3840px) {
        width: 30rem;
        right: 7rem;
        top: 6rem;
    }
`;

const StyledQrCodeContainer = styled.section`
    position: absolute;
    bottom: 4rem;
    right: 4rem;
`;

export default function Event({data}) {
    getDate(data);
    function getDate(data) {
        let currentDate = (data.startDate.split("-"));
        let currentMonth = parseInt(currentDate[1]);
        let currentDay = currentDate[2];
        let month = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];
        let currentTime = data.startTime.split(':').slice(0, 2).join(':');

        return currentDay + " " + month[currentMonth - 1] + ', ' + currentTime + ' uur';
    }
    return (
        <StyledEvent style={{backgroundImage: `url('${data.images[0]}')`}}>
            <StyledSection>
                <StyledTextArea>
                    <StyledHeading>{data.title}</StyledHeading>
                    <StyledText>{data.text}</StyledText>
                    <StyledDate>{getDate(data)}</StyledDate>
                </StyledTextArea>
                <StyledTriangles />
            </StyledSection>
            <StyledQrCodeContainer>
                <QrCodeContent qrCode ={data.qrCodeUrl} ></QrCodeContent>
            </StyledQrCodeContainer>
            <StyledLogo />
        </StyledEvent>
    );
}
import React from 'react';
import styled from 'styled-components';
import {CategoryScale, Chart as ChartJS} from 'chart.js/auto';
import {Line} from "react-chartjs-2";

const StyledWeatherRain = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;
        
    background-color: var(--color-blue);
    border-radius: 1.25rem;
    padding: 4rem;
`;

const StyledHeading1 = styled.h1`
    position: absolute;
    top: 7.5rem;
    font-family: var(--font-header);
    font-size: var(--fs-heading-1);
    font-weight: var(--fw-bold);


    @media screen and (min-width: 3840px) {
        font-size: 7.5rem;
    }
`;

const StyledLine = styled(Line)`
    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
`;

export default function WeatherRain({data}) {
    ChartJS.register(CategoryScale);

    let labels = [];
    let values = [];

    data.forEach(mapRain)
    let highestValue = Math.max(...values);
    let maxValue = highestValue.toFixed(2);
    // minimum van 1 nodig om de y-as weer te geven
    if (maxValue > 3){
        maxValue += 1.2;
    } else {
        maxValue = 3;
    }

    function mapRain(item, index){
        labels.push(item['time']);
        values.push(item['amount']);
    }

    const chart = {
        labels: labels,
        datasets: [
            {
                data: values,
                fill: true,
                backgroundColor: 'rgba(126, 105, 146, 0.5)',
                borderColor: '#211845',
                borderWidth: 5,
                radius: 0,
            }
        ]
    };

    const lightRain = {
        id: 'lightRain',
        beforeDatasetsDraw(chart, args, pluginOptions) {
            const {ctx, chartArea: {left, right, width}, scales: {y}} = chart;
            ctx.save();

            ctx.strokeStyle = '#7E6992';
            ctx.lineWidth = 3;
            ctx.beginPath();
            ctx.moveTo(left, y.getPixelForValue(0.5));
            ctx.lineTo(right, y.getPixelForValue(0.5));
            ctx.stroke();
            ctx.restore();

            ctx.font = '2.5rem Poppins';
            ctx.fillStyle = '#211845';
            ctx.fillText('licht', width, y.getPixelForValue(0.55) - 0.55);
            ctx.restore();
        }
    }

    const mediumRain = {
        id: 'mediumRain',
        beforeDatasetsDraw(chart, args, pluginOptions) {
            const {ctx, chartArea: {left, right, width}, scales: {y}} = chart;
            ctx.save();

            ctx.strokeStyle = '#7E6992';
            ctx.lineWidth = 3;
            ctx.beginPath();
            ctx.moveTo(left, y.getPixelForValue(1.5));
            ctx.lineTo(right, y.getPixelForValue(1.5));
            ctx.stroke();
            ctx.restore();

            ctx.font = '2.5rem Poppins';
            ctx.fillStyle = '#211845';
            ctx.fillText('matig', width, y.getPixelForValue(1.55) - 1.55);
            ctx.restore();
        }
    }

    const heavyRain = {
        id: 'heavyRain',
        beforeDatasetsDraw(chart, args, pluginOptions) {
            const {ctx, chartArea: {left, right, width}, scales: {y}} = chart;
            ctx.save();

            ctx.strokeStyle = '#7E6992';
            ctx.lineWidth = 3;
            ctx.beginPath();
            ctx.moveTo(left, y.getPixelForValue(3.5));
            ctx.lineTo(right, y.getPixelForValue(3.5));
            ctx.stroke();
            ctx.restore();

            ctx.font = '2.5rem Poppins';
            ctx.fillStyle = '#211845';
            ctx.fillText('zwaar', width, y.getPixelForValue(3.55) - 3.55);
            ctx.restore();
        }
    }

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                min: 0,
                max: maxValue,
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        size: 20,
                        family: 'Poppins'
                    },
                    callback: function(value, index, ticks){
                        return value + ' mm';
                    }
                },
            },
            x: {
                ticks: {
                    font: {
                        size: 20,
                        family: 'Poppins'
                    },
                    callback: function(val, index){
                        return index % 3 === 0 ? this.getLabelForValue(val) : '';
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            // title: {
            //     display: true,
            //     text: 'Verwachte regen vandaag',
            //     color: '#211845',
            //     fullSize: true,
            //     font: {
            //         size: 45,
            //         family: "Poppins"
            //     }
            // },

        },
        animation: false,
    }

    return (
        <StyledWeatherRain>
            <StyledHeading1>Verwachte regen komende 2 uur</StyledHeading1>
            <StyledLine data={chart} options={options} plugins={[lightRain, mediumRain, heavyRain]} />
        </StyledWeatherRain>
    );
}